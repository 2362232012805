export default (allPaths_v2, nodeMap) => {
    if (allPaths_v2 === undefined) return;
    const paths = {}
    Object.keys(allPaths_v2).forEach(key => {
        const path = allPaths_v2[key]
        const distance = path.length
        const destination = path[path.length - 1];
        const destination_node = nodeMap[destination]
        paths[destination] = {
            path: path,
            distance: distance,
            node: destination_node
        }
    });

    return paths
}