import { useState, useEffect } from 'react';

const usePageVisibility = () => {
  const [isVisible, setIsVisible] = useState(!document?.hidden);

  const handleVisibilityChange = () => {
    setIsVisible(!document?.hidden);
  };

  useEffect(() => {
    document?.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document?.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isVisible;
};

export default usePageVisibility;
