import constants from "../helpers/constants";

export default (path, networkRef, setNodesToUpdate) => {
  if (path === undefined) {
    return
  }

  if (networkRef.current === undefined) {
    return
  }

  const nodes = networkRef.current.body.data.nodes;

  path.forEach(nodeId => {
    nodes.update({ id: nodeId, ...constants.graph.nodes.style.selected });
  });

  setNodesToUpdate((prevNodesToUpdate) => {
    prevNodesToUpdate.forEach(nodeId => {
      if (path.includes(nodeId)){
        return 
      }

      nodes.update({ id: nodeId, ...constants.graph.nodes.style.normal });
    });
    return path
  })
}