import handleKeyPressToNavigate from '../handlers/handleKeyPressToNavigate';

export function handleGraphKeyPress(setOpenPanel, selecting, networkRef, path, setPath, pathIndex_x, pathIndex_y, setPathIndex_x, setPathIndex_y, events, allPaths, graph, setScrollToId, nodeMap) {
  const onKeyPress = (keyPressEvent) => {
    setOpenPanel(false);
    if (selecting) {
      return;
    }

    const newNodeId = handleKeyPressToNavigate(
      keyPressEvent,
      networkRef,
      path,
      setPath,
      pathIndex_x,
      pathIndex_y,
      setPathIndex_x,
      setPathIndex_y,
      events,
      allPaths,
      graph,
      setOpenPanel,
      setScrollToId,
      nodeMap
    );

    if (newNodeId) {
      networkRef.current.selectNodes([newNodeId]);
      events.select({
        nodes: [newNodeId],
        edges: [],
        event: null,
        pointer: null,
      });
    }
  };
  window.addEventListener('keyup', onKeyPress);

  return () => {
    window.removeEventListener('keyup', onKeyPress);
  };
}
