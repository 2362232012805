export default (nodeId, networkRef, xOffset = 0, yOffset = 0) => {
    // let position = networkRef.current.getPositions([nodeId]);
    // if (position[nodeId]) {
        let focusOptions = {
            scale: 0.70,
            animation: true,
            offset: {
                x: xOffset,
                y: yOffset
            }
        }
        networkRef.current.focus(nodeId, focusOptions);
        return
    // }
};