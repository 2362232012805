export default (data) => {
    let paths = [];

    for (let key in data) {
        if (data.hasOwnProperty(key)) {
            if (data[key]) {
                paths.push(data[key].path);    
            }
        }
    }

    return paths;
}