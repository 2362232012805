import capitalism from "../../capitalism.json"
import softwareDesignPatterns from "../../software-design-patterns.json"
import { addOrUpdateTopic, getTopicByName } from "./indexedDb"
import transformRelationalMapToGraph from "./transformRelationalMapToGraph.js"

export default async (topic) => {
    switch (topic) {
        // case 'capitalism':
        //     return capitalism;
        // case 'software-design-patterns':
        //     return softwareDesignPatterns;
        default:
            const newData = await getTopicByName(topic)
            if (newData.relationalMap) {
                const newGraph = transformRelationalMapToGraph(newData.relationalMap, topic)
                return newGraph
            }

            const newGraph = {
                nodes: [{
                    "id": 1,
                    "label": topic,
                    "title": topic,
                    "end_of_the_road": false,
                    "is_antithesis": false,
                    "is_main_topic": true
                }],
                edges: []
            }

            await addOrUpdateTopic(newData)
            return newGraph

    }
};
