import 'react-sliding-side-panel/lib/index.css';
import Graph from "react-vis-network-graph";

import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import constants from '../helpers/constants';
import getNodeMap from '../nodes/getNodeMap';
import getMainNodeFromGraph from '../nodes/getMainNodeFromGraph';
import flattenPaths from '../helpers/flattenPaths';
import getAllPaths_v2 from '../helpers/getAllPaths_v2';
import getEdgesMap from '../nodes/getEdgesMap';
import highlightEdgesPath from '../nodes/highlightEdgesPath';
import highlightPathNodes from '../nodes/highlightPathNodes';
import highlightNodeByHover from '../nodes/highlightNodeByHover';
import focusOnNode from "../nodes/focusOnNode";
import getGraph from '../helpers/getGraph';
import hydratePaths from '../helpers/hydratePaths';
import Loading from './Loading';
import fetchGraphData from '../helpers/fetchGraphData';
import { getTopicByName, addOrUpdateTopic, getAllTopics, deleteTopic } from '../helpers/indexedDb';
import { handleScreenResize } from './handleScreenResize';
import { updateNodes } from './updateNodes';
import { updateEdges } from './updateEdges';
import { handleGraphKeyPress } from './handleGraphKeyPress';
import getTopicTree from './getTopicTree';
import { getNodeIdByLabel } from './getNodeIdByLabel';
import TextWidget from './TextWidget';
import usePageVisibility from '../hooks/usePageVisibility';
import { removeSubArrays } from './removeSubArrays';
import { mergePaths } from './mergePaths';
import { makeid } from './makeid';
import Navbar from './Navbar';

export default (props) => {
  const isVisible = usePageVisibility()
  const networkRef = useRef()
  const timeoutRefForGraphUpdate = useRef(null);
  const { topic } = useParams()
  const [openPanel, setOpenPanel] = useState(false)
  const [screenWidth, setScreenWidth] = useState(512)
  const [screenHeight, setScreenHeight] = useState(512)
  const [isLoading, setIsLoading] = useState(true)
  const [currentTopic] = useState(topic || props.topic)
  const [graph, setGraph] = useState()
  const [firstGraphData, setFirstGraphData] = useState()
  const [nodeMap, setNodeMap] = useState({})
  const [edgesMap, setEdgesMap] = useState({})
  const [pathIndex_x, setPathIndex_x] = useState(0)
  const [pathIndex_y, setPathIndex_y] = useState(0)
  const [allPaths, setAllPaths] = useState()
  const [allPathsArray, setAllPathsArray] = useState()
  const [selectedNode, setSelectedNode] = useState()
  const [nodeToUpdateByHover, setNodeToUpdateByHover] = useState()
  const [path, setPath] = useState()
  const [searchTermForPath, setSearchTermForPath] = useState([])
  const [selectedPath, setSelectedPath] = useState([])
  const [edgesToUpdate, setEdgesToUpdate] = useState([])
  const [nodesToUpdate, setNodesToUpdate] = useState([])
  const [events, setEvents] = useState()
  const [selecting, setSelecting] = useState(false)
  const [allTopics, setAllTopics] = useState([])
  const [currentTopicData, setCurrentTopicData] = useState({})
  const [scrollToId, setScrollToId] = useState()
  const [scrollToIdHash, setScrollToIdHash] = useState()
  const [updateIndex, setUpdateIndex] = useState(0)
  const [playing, setPlaying] = useState(false)
  const [topicTree, setTopicTree] = useState(null)
  const [mouseMoveCounter, setMouseMoveCounter] = useState(0)
  const [autoPilotPosition, setAutoPilotPosition] = useState({ x: 0, y: 0 })
  const [pathFromSelectedNodeToMainNodeTitles, setPathFromSelectedNodeToMainNodeTitles] = useState([])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMouseMoveCounter(prevCounter => {
        if (prevCounter > 60 * 5) {
          setMouseMoveCounter(0)

          if (isVisible) {
            setPlaying(true)
          }
        }

        return prevCounter + 1
      });
    }, 1000);

    return () => clearTimeout(timeout);
  }, [mouseMoveCounter, isVisible]);


  useEffect(() => {
    const handleMouseMove = () => {
      setMouseMoveCounter(0);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchstart', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchstart', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (allPathsArray === undefined) return
    if (!playing) return

    if (!isVisible) {
      setPlaying(false)
      return
    }

    let actualPath = allPathsArray[autoPilotPosition.x]
    let nodeId = actualPath[autoPilotPosition.y]
    let label = nodeMap[nodeId].label
    setScrollToId(label)
    setScrollToIdHash(makeid(5))

    var newY = autoPilotPosition.y + 1
    var newX = autoPilotPosition.x
    if (actualPath[newY] === undefined) {
      newY = 0
      newX = newX + 1
      if (allPathsArray[newX] === undefined) {
        newX = 0
      }
    }

    let defaultInterval = 2000
    let dynamicInterval = label.replace(/\n/g, " ").length * 100
    let timeout = setTimeout(() => {
      setAutoPilotPosition({
        x: newX,
        y: newY
      })
    }, dynamicInterval > defaultInterval ? dynamicInterval : defaultInterval)

    return () => clearTimeout(timeout)

  }, [playing, autoPilotPosition])

  const handlePlayClick = () => {
    if (playing) {
      setPlaying(false)
      return;
    }

    setPlaying(true)
  }

  const handleDeleteClick = async () => {
    try {
      await deleteTopic(currentTopic)
      window.location.reload()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const loadGraph = async () => {
      if (currentTopic === undefined) return

      const newGraph = await getGraph(currentTopic)
      setGraph(newGraph)

      if (!networkRef.current) {
        setFirstGraphData(newGraph)
      }

      if (networkRef.current) {
        setUpdateIndex((prev) => prev + 1)
        updateNodes(newGraph, networkRef)
        updateEdges(newGraph, networkRef)
      }

      const newAllTopics = await getAllTopics()
      const newCurrentTopicData = newAllTopics.filter(obj => obj.name === currentTopic)[0];
      setCurrentTopicData(newCurrentTopicData)
      setAllTopics(newAllTopics)

      const newData = await getTopicByName(currentTopic)
      if (newData.status === 204) {
        return
      }

      const graphData = await fetchGraphData(newData)
      await addOrUpdateTopic(graphData)

      timeoutRefForGraphUpdate.current = setTimeout(() => {
        loadGraph()
      }, 500);
    }

    if (timeoutRefForGraphUpdate.current) {
      clearTimeout(timeoutRefForGraphUpdate.current);
    }

    timeoutRefForGraphUpdate.current = setTimeout(() => {
      loadGraph()
    }, 500);
  }, [currentTopic])

  useEffect(() => {
    if (graph === undefined) { return }

    const mainNode = getMainNodeFromGraph(graph)
    if (mainNode === undefined) { return }

    const nodeMap = getNodeMap(graph.nodes)
    setNodeMap(nodeMap)

    const edgesMap = getEdgesMap(graph.edges)
    setEdgesMap(edgesMap)

    setSelectedNode(mainNode.id)
    setNodeToUpdateByHover(mainNode.id)

    const paths = hydratePaths(getAllPaths_v2(graph, mainNode.id), nodeMap)
    setAllPaths(paths)
    setPath(flattenPaths(paths))

    let pathsRaw = Object.values(paths).map(item => item.path)
    pathsRaw.sort((a, b) => b.length - a.length)
    let pathsWithoutSimilarSubpaths = removeSubArrays(pathsRaw)
    let pathsWithoutFirstStep = pathsWithoutSimilarSubpaths.map(array => array.slice(1))
    let pathsMerged = mergePaths(pathsWithoutFirstStep)
    let pathsArray = pathsMerged.map(array => { return [pathsRaw[0][0], ...array]; })
    pathsArray.sort((a, b) => b.length - a.length);
    setAllPathsArray(pathsArray)

    setEvents({
      hoverNode: function (event) {
        highlightNodeByHover(event.node, networkRef, setNodeToUpdateByHover, paths, setSelectedNode)
      },
      blurNode: function () {
        highlightNodeByHover(null, networkRef, setNodeToUpdateByHover, paths, setSelectedNode)
      },
      select: (selection) => {
        var { nodes } = selection;
        if (!nodes.length) return

        const selectedNodeId = nodes[0]
        setSelectedNode(selectedNodeId)
        setOpenPanel(true)

        const pathToMainTopic = paths[selectedNodeId]
        if (pathToMainTopic.path[pathToMainTopic.path.length - 1] === mainNode.id) { pathToMainTopic.path.reverse(); }
        setPathFromSelectedNodeToMainNodeTitles([...pathToMainTopic.path].reverse().map((nodeId) => nodeMap[nodeId].title))

        if (pathToMainTopic) {
          highlightPathNodes(
            pathToMainTopic.path,
            networkRef,
            setNodesToUpdate
          )

          highlightEdgesPath(
            pathToMainTopic.path,
            edgesMap,
            setEdgesToUpdate,
            networkRef
          )

          const terms = pathToMainTopic.path.map((nodeId) => {
            return nodeMap[nodeId].label
          }).reverse()

          setSearchTermForPath(terms)
          setSelectedPath(pathToMainTopic.path.reverse())
        }
      }
    })

    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [graph, updateIndex])

  useEffect(() => {
    if (networkRef.current === undefined) {
      return
    }

    const nodeId = getNodeIdByLabel(nodeMap, scrollToId)
    let xOffset = screenWidth > 1100 ? -300 : 0
    let yOffset = screenWidth > 1100 ? -100 : -200
    focusOnNode(nodeId, networkRef, xOffset, yOffset)

    networkRef.current.selectNodes([nodeId]);

    events.select({
      nodes: [nodeId],
      edges: [],
      event: null,
      pointer: null,
    });
  }, [scrollToId, scrollToIdHash])

  useEffect(() => {
    return handleScreenResize(setScreenWidth, setScreenHeight);
  }, [])

  useEffect(() => {
    return handleGraphKeyPress(setOpenPanel, selecting, networkRef, path, setPath, pathIndex_x, pathIndex_y, setPathIndex_x, setPathIndex_y, events, allPaths, graph, setScrollToId, nodeMap);
  }, [path, graph, pathIndex_x, pathIndex_y, selecting]);

  useEffect(() => {
    const topicTree = getTopicTree(currentTopicData, currentTopic);
    setTopicTree(topicTree);
  }, [currentTopicData, currentTopic]);

  if (isLoading
    || nodeMap === undefined
    || nodeMap[selectedNode] === undefined
  ) {
    return <Loading isLoading={true} />
  }

  return (
    <>
      <Loading isLoading={false} />
      <Navbar
        currentTopic={currentTopic}
        setSelecting={setSelecting}
        handlePlayClick={handlePlayClick}
        playing={playing}
        handleDeleteClick={handleDeleteClick} />
      <div className='App'>
        <Graph
          getNetwork={(network) => { networkRef.current = network; }}
          graph={firstGraphData}
          options={{ ...constants.graph.options, ...{ height: `${screenHeight}px`, width: `${screenWidth}px` } }}
          events={events}
        />
        <TextWidget
          title={currentTopic}
          subtitle={nodeMap[selectedNode].label}
          pathFromSelectedNodeToMainNodeTitles={pathFromSelectedNodeToMainNodeTitles}
          setScrollToId={setScrollToId}
          isPlaying={playing}
          tree={topicTree}
          setSelecting={setSelecting}
          currentTopicData={currentTopicData}
          setCurrentTopicData={setCurrentTopicData}
        />
      </div>
    </>
  );
}