export default (nodes) => {
    return nodes.reduce((acc, item, index) => {
        acc[item.id] = {
            label: item.label,
            title: item.title,
            end_of_the_road: item.end_of_the_road,
            is_antithesis: item.is_antithesis,
            index_on_nodes_array: index
        };
        return acc;
    }, {});
}