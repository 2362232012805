export default (currentTopicData, topic) => {
  const trees = []
  try {
    const roadmapDirectory = {
      name: "Know How",
      type: "directory",
      children: []
    }
    if (currentTopicData && currentTopicData.stepByStepTopics) {
      currentTopicData.stepByStepTopics.forEach((item) => {
        roadmapDirectory.children.push({ name: item, type: "file" })
      })
    }
    trees.push(roadmapDirectory)

    const fundamentalsDirectory = {
      name: "Fundamentals",
      type: "directory",
      children: []
    }
    if (currentTopicData && currentTopicData.fundamentals) {
      currentTopicData.fundamentals.forEach((item) => {
        fundamentalsDirectory.children.push({ name: item, type: "file" })
      })
    }
    trees.push(fundamentalsDirectory)

    const authorsDirectory = {
      name: "Authors",
      type: "directory",
      children: []
    }
    const authors = []
    if (currentTopicData && currentTopicData.authorsFavorA) {
      currentTopicData.authorsFavorA.forEach((author) => {
        if (authors.includes(author)) { return }
        authors.push(author);
      });
    }
    if (currentTopicData && currentTopicData.authorsAgainstA) {
      currentTopicData.authorsAgainstA.forEach((author) => {
        if (authors.includes(author)) { return }
        authors.push(author);
      });
    }
    authors.forEach((item) => {
      authorsDirectory.children.push({ name: item, type: "file" })
    })
    trees.push(authorsDirectory)

  } catch (error) {
    console.error(error);
  }

  return trees;
}
