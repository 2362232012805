import focusOnNode from "../nodes/focusOnNode"
import fitNodesOnScreen from "../nodes/fitNodesOnScreen"
import findPath from "../helpers/findPath"

export default (keyPressEvent, networkRef, path, setPath, pathIndex_x, pathIndex_y, setPathIndex_x, setPathIndex_y, events, allPaths, graph, setOpenPanel,       setScrollToId,
    nodeMap) => {
    var newPathIndex_x = pathIndex_x
    var newPathIndex_y = pathIndex_y
    switch (keyPressEvent.key) {
        case 'ArrowRight':
            if (newPathIndex_y + 1 < path[newPathIndex_x].length) {
                newPathIndex_y += 1
            } else {
                newPathIndex_y = 0
                if (newPathIndex_x + 1 <= path.length) {
                    newPathIndex_x += 1
                }
            }
            break;
        case 'ArrowLeft':
            if (newPathIndex_y - 1 >= 0) {
                newPathIndex_y -= 1
            } else {
                newPathIndex_y = path[newPathIndex_x].length - 1
                newPathIndex_x -= 1
            }

            break;
        case 'f': {
            fitNodesOnScreen(networkRef)
            break;
        } case 'r': {
            const nodeIndices = networkRef.current.body.nodeIndices
            const randomArrayIndex = Math.floor(Math.random() * nodeIndices.length)
            const destinationId = nodeIndices[randomArrayIndex]
            const startId = path[0][0]
            const newPath = findPath(graph, destinationId, startId)
            setPath([newPath])
            setPathIndex_x(newPathIndex_x)
            setPathIndex_y(newPathIndex_y)
            setScrollToId(nodeMap[destinationId].label)
            // focusOnNode(destinationId, networkRef)
            // setTimeout(() => {
            //     setOpenPanel(true)
            // }, 500)
            // networkRef.current.selectNodes([destinationId]);
            // events.select({
            //     nodes: [destinationId],
            //     edges: [],
            //     event: null,
            //     pointer: null,
            // });
            break;
        } case 'p': {
            const positions = networkRef.current.getPositions();
            console.log(positions)
            break;
        } case 'r':
          case ' ': {
            const nodeId = path[newPathIndex_x][newPathIndex_y]
            // focusOnNode(nodeId, networkRef)
            setPathIndex_x(newPathIndex_x)
            setPathIndex_y(newPathIndex_y)
            return nodeId
        }
    }

    
}