import "../../css/ConnectedBulletList.css";

const ConnectedBulletList = ({ items }) => {
    return <ul class="commit-list">
        <li class="commit-item p-0 m-0">
                <div class="commit-hash">{}</div>
                <div class="commit-connector"></div>
            </li>
        {items.slice(1).map((item) => {
            return <li class="commit-item">
                <div class="commit-circle"></div>
                <div class="commit-hash">{item}</div>
                <div class="commit-connector"></div>
            </li>
        })}
    </ul>
};

export default ConnectedBulletList