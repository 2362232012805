import { FaDice } from "react-icons/fa6";
import { TbZoomScan } from "react-icons/tb";
import { pressKeyF } from './pressKeyF';
import { pressKeyR } from './pressKeyR';
import { TbRefreshDot } from "react-icons/tb";
import { IoPlay } from "react-icons/io5";
import { IoIosPause } from "react-icons/io";
import GraphSelector from './GraphSelector'

export default ({
    currentTopic,
    setSelecting,
    handlePlayClick,
    playing,
    handleDeleteClick
}) => {
    return <div className='navbar'>
        <div className='navbar-item clean'>
            <GraphSelector topic={currentTopic} setSelecting={setSelecting} />
        </div>
        <button onClick={pressKeyR} className='navbar-item icon'>
            <FaDice />
        </button>
        <button onClick={handlePlayClick} className='navbar-item icon'>
            {playing ? <IoIosPause /> : <IoPlay />}
        </button>
        <button onClick={pressKeyF} className='navbar-item icon'>
            <TbZoomScan />
        </button>
        <button onClick={handleDeleteClick} className='navbar-item icon'>
            <TbRefreshDot />
        </button>
    </div>;
}