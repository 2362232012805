export function mergePaths(paths) {
  let pathMap = {};
  paths.forEach(path => {
    const initialSteps = path.slice(0, -1).join('-');
    if (pathMap[initialSteps]) {
      pathMap[initialSteps].push(path[path.length - 1]);
    } else {
      pathMap[initialSteps] = path.slice();
    }
  });

  const mergedPaths = Object.values(pathMap).map(path => {
    return path;
  });

  return mergedPaths;
}
