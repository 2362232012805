import { edgeExists } from './edgeExists';

export function updateEdges(newGraph, networkRef) {
  let filteredNewNodes = newGraph.nodes.filter(node => !networkRef.current.body.nodeIndices.includes(node.id));
  filteredNewNodes.forEach((node) => {
    networkRef.current.body.data.nodes.add(node);
  });

  const edges = networkRef.current.body.data.edges.get();
  const filteredNewEdges = newGraph.edges.filter(edge => !edgeExists(edge, edges));
  filteredNewEdges.forEach((edge) => {
    networkRef.current.body.data.edges.add(edge);
  });
}
