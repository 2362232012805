export function pressKeyR() {
  var event = new KeyboardEvent('keyup', {
    key: 'r',
    code: 'KeyR',
    keyCode: 82,
    charCode: 82,
    view: window,
    bubbles: true,
    cancelable: true
  });

  window.dispatchEvent(event);
}
