export const removeSubArrays = (arrays) => {
  arrays = JSON.parse(JSON.stringify(arrays));

  function isSubset(arr1, arr2) {
    return arr1.every(item => arr2.includes(item));
  }

  let filtered = arrays.filter((arr1, i) => {
    for (let j = 0; j < arrays.length; j++) {
      if (i !== j) {
        let arr2 = arrays[j];
        if (isSubset(arr1, arr2)) {
          return false;
        }
      }
    }
    return true;
  });

  return filtered;
};
