function findPaths(graph, startNodeId) {
    const nodes = graph.nodes.reduce((acc, node) => {
        acc[node.id] = {
            ...node,
            distance: Infinity,
            previous: null
        };
        return acc;
    }, {});

    if (nodes[startNodeId] === undefined) return;
    nodes[startNodeId].distance = 0;

    const adjacencyList = graph.edges.reduce((acc, edge) => {
        if (!acc[edge.from]) acc[edge.from] = [];
        if (!acc[edge.to]) acc[edge.to] = [];
        acc[edge.from].push(edge.to);
        acc[edge.to].push(edge.from);
        return acc;
    }, {});

    const unvisited = new Set(Object.keys(nodes).map(Number));

    while (unvisited.size > 0) {
        const currentNode = [...unvisited].reduce((minNode, nodeId) => {
            return nodes[nodeId].distance < nodes[minNode].distance ? nodeId : minNode;
        });

        unvisited.delete(currentNode);

        const neighbors = adjacencyList[currentNode] || [];
        neighbors.forEach(neighborId => {
            if (unvisited.has(neighborId)) {
                const newDist = nodes[currentNode].distance + 1;
                if (newDist < nodes[neighborId].distance) {
                    nodes[neighborId].distance = newDist;
                    nodes[neighborId].previous = currentNode;
                }
            }
        });
    }

    const paths = {};
    for (const nodeId in nodes) {
        if (nodes.hasOwnProperty(nodeId)) {
            const path = [];
            let currentNode = parseInt(nodeId);
            while (currentNode !== null) {
                path.unshift(currentNode);
                currentNode = nodes[currentNode].previous;
            }
            paths[nodeId] = path;
        }
    }

    return paths;
}
export default (graph, startNodeId) => {
    return findPaths(graph, startNodeId);
}