export default (graph, startNodeId, destinationNodeId) => {
    // Create a map of adjacency list from the graph edges
    const adjacencyList = {};
    graph.nodes.forEach(node => adjacencyList[node.id] = []);
    graph.edges.forEach(edge => {
        adjacencyList[edge.from].push(edge.to);
        adjacencyList[edge.to].push(edge.from); // Assuming the graph is undirected
    });

    // BFS to find the shortest path
    const queue = [[startNodeId]];
    const visited = new Set();

    while (queue.length > 0) {
        const path = queue.shift();
        const node = path[path.length - 1];

        if (node === destinationNodeId) {
            return path;
        }

        if (!visited.has(node)) {
            visited.add(node);
            const neighbors = adjacencyList[node];

            for (const neighbor of neighbors) {
                const newPath = path.slice();
                newPath.push(neighbor);
                queue.push(newPath);
            }
        }
    }

    return null; // Return null if no path is found
}
