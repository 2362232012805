export default (str, N) => {
    if (N <= 0) return [];

    const words = str.split(' ');
    let result = [];
    let currentLine = '';

    for (let word of words) {
        if ((currentLine + ' ' + word).trim().length <= N) {
            currentLine = (currentLine + ' ' + word).trim();
        } else {
            result.push(currentLine);
            currentLine = word;
        }
    }

    if (currentLine.length > 0) {
        result.push(currentLine);
    }

    return result;
}