import React, { useState } from 'react';
import { CgMinimizeAlt } from "react-icons/cg";
import { LuMaximize2 } from "react-icons/lu";
import TopicTree from './TopicTree';
import TextWidgetQuestions from './TextWidgetQuestions';
import 'react-loading-skeleton/dist/skeleton.css'
import "../../css/TextWidget.css";
import TextWidgetSearchOn from './TextWidgetSearchOn';
import ConnectedBulletList from './ConnectedBulletList';

const MinimizeButton = ({ onClick }) => <div onClick={onClick} className='navbar-item m-0 icon-sm items-center me-2'><CgMinimizeAlt /></div>
const MaxmizeButton = ({ onClick }) => <div onClick={onClick} className='navbar-item m-0 icon-sm items-center me-2'><LuMaximize2 /></div>

export default ({
  title,
  subtitle,
  pathFromSelectedNodeToMainNodeTitles,
  setScrollToId,
  tree,
  setSelecting,
  currentTopicData,
  setCurrentTopicData
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const toggleContent = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="chat-widget text-medium font-medium text-default-400">
      <div className='flex justify-content-between pe-4 pb-3'>
        <div className='display flex align-items-start'>
          {isExpanded ? <MinimizeButton onClick={toggleContent} /> : <MaxmizeButton onClick={toggleContent} />}
          <div>
            {subtitle}
          </div>
        </div>
      </div>

      {isExpanded && (
        <>
          <div className="content-wrapper">
            <div className="content">
              <ConnectedBulletList items={pathFromSelectedNodeToMainNodeTitles} />
              <TopicTree setScrollToId={setScrollToId} data={tree} />
              <TextWidgetQuestions
                title={title}
                subtitle={subtitle}
                setSelecting={setSelecting}
                currentTopicData={currentTopicData}
                setCurrentTopicData={setCurrentTopicData} />
              <TextWidgetSearchOn searchTerm={`${title} ${subtitle == title ? "" : subtitle}`} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}