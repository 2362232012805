import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../../css/TextWidgetQuestions.css";
import { useEffect, useRef, useState } from 'react';
import fetchContent from '../helpers/fetchContent';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { addOrUpdateTopic } from '../helpers/indexedDb';

const questions = [
    'What is it?',
    'Why is it important?',
    'How does it work?',
    'What are its origins?',
    'Who is involved or affected?',
    'What are the benefits and drawbacks?',
    'How can it be applied or used?',
    'What are the challenges or obstacles?',
    'What are the future possibilities or developments?',
    'What are the alternative perspectives?',
    'How is it measured or evaluated?',
    'How has it evolved over time?',
    'What are the critical success factors?',
    'What are the best practices?',
    'What are the common misconceptions?',
    'How does it fit into the larger context?',
    'What resources are required?',
    'What are the next steps? Actions and follow-up',
    'What are the underlying assumptions? Basic premises and presuppositions'
]

const Question = ({ children, title, subtitle, actualSlide, nextSlide, previousSlide, currentTopicData, setCurrentTopicData }) => {
    const [answered, setAnswered] = useState(false)
    const [textAreaValue, setTextAreaValue] = useState("")
    const [correction, setCorrection] = useState("")

    useEffect(() => {
        if (currentTopicData === undefined) return
        if (currentTopicData.answers === undefined) return
        if (currentTopicData.answers[`${subtitle}:${children}`] === undefined) {
            setTextAreaValue('')
            return
        }

        setTextAreaValue(currentTopicData.answers[`${subtitle}:${children}`])
    }, [subtitle])

    useEffect(() => {
        if (currentTopicData === undefined) return
        if (currentTopicData.corrections === undefined) return
        if (currentTopicData.corrections[`${subtitle}:${children}`] === undefined) {
            setAnswered(false)
            setCorrection("")
            return
        }

        setAnswered(true)
        setCorrection(currentTopicData.corrections[`${subtitle}:${children}`])
    }, [subtitle])

    const handleChange = (event) => {
        const updateData = async (data) => {
            await addOrUpdateTopic(data)
        }

        setTextAreaValue(event.target.value);
        if (currentTopicData.answers === undefined) return
        currentTopicData.answers[`${subtitle}:${children}`] = event.target.value
        updateData(currentTopicData)
        setCurrentTopicData(currentTopicData)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit();
        }
    }

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        let tempTextAreaValue = textAreaValue !== "" ? textAreaValue : "I don't know"
        setTextAreaValue(tempTextAreaValue)
        const getCorrection = async () => {
            setAnswered(true)
            const contentText = await fetchContent({ input: `Topic:${title}\n\nSubtopic:${subtitle}\n\nQuestion: ${children}\n\nAnswer:${tempTextAreaValue}\n\n1) This answer is right? (Act like a specialist on the field and be critical)` })
            setCorrection(contentText)
            
            if (currentTopicData.answers === undefined) { currentTopicData.answers = {} }
            if (currentTopicData.corrections === undefined) { currentTopicData.corrections = {} }
            currentTopicData.answers[`${subtitle}:${children}`] = tempTextAreaValue
            currentTopicData.corrections[`${subtitle}:${children}`] = contentText
            await addOrUpdateTopic(currentTopicData)
        }

        getCorrection()
    }

    const resetAnswers = () => {
        const updateData = async (data) => {
            await addOrUpdateTopic(data)
        }
        const confirmed = window.confirm('Are you sure you want to reset the answers from this topic?');
        if (!confirmed) return
        currentTopicData.answers = {}
        currentTopicData.corrections = {}
        updateData(currentTopicData)
        setCurrentTopicData(currentTopicData)
        setAnswered(false)
        setCorrection("")
        setTextAreaValue("")
    }

    return <div className='question'>
        <div className='title'>{children}</div>
        <form className='answer' onSubmit={handleSubmit}>
            <div className="form-group">
                <label for="textAreaAnswer">{answered ? "You answer" : "Write the answer"}</label>
                <textarea
                    defaultValue={textAreaValue}
                    disabled={answered}
                    readOnly={answered}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    className="form-control" id="textAreaAnswer" rows="3"></textarea>
            </div>
        </form>
        <div className='answer'>
            <div>{!correction && answered ? <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <Skeleton count={5} />
            </SkeletonTheme> : <div className='mb-3'><div>{answered && "Correction"}</div>{correction}</div>}</div>
        </div>
        <div className='d-flex justify-content-between'>
            <div>
                <button className='navbar-item ms-0' onClick={previousSlide}>Previous</button>
            </div>
            <div className='d-flex justify-content-end'>
                {answered ? null : <button className='navbar-item' onClick={handleSubmit}>{textAreaValue === '' ? "I don't know" : "Send"}</button>}
                <button className='navbar-item me-0' onClick={nextSlide}>Next</button>
            </div>
        </div>
        <div className='pt-4 small flex justify-content-end'><span onClick={resetAnswers} className='pointer'>Reset answers</span></div>
    </div>
}

export default ({ title, subtitle, setSelecting, currentTopicData, setCurrentTopicData }) => {
    const containerRef = useRef(null)
    const swiperRef = useRef(null)
    const [actualSlide, setActualSlide] = useState(0)

    useEffect(() => {
        if (currentTopicData === undefined) return
        if (currentTopicData.answers === undefined) { currentTopicData.answers = {} }
        if (currentTopicData.corrections === undefined) { currentTopicData.corrections = {} }
    }, [])

    const nextSlide = () => {
        let totalSlides = swiperRef.current.swiper.slides.length;
        let next = actualSlide + 1
        if (next > totalSlides) {
            swiperRef.current.swiper.slideTo(0)
            setActualSlide(0)
            return;
        }
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(next)
            setActualSlide(next)
        }
    };

    const previousSlide = () => {
        if (actualSlide == 0) {
            let totalSlides = swiperRef.current.swiper.slides.length;
            swiperRef.current.swiper.slideTo(totalSlides)
            setActualSlide(totalSlides)
            return
        }

        if (swiperRef.current && swiperRef.current.swiper) {
            let previous = actualSlide - 1
            swiperRef.current.swiper.slideTo(previous)
            setActualSlide(previous)
        }
    };

    const scrollToQuestTitle = () => {
        const container = document.querySelector('.content-wrapper');
        const element = document.querySelector('.question .title');
        const elementRect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        const yOffset = -25;
        const y = elementRect.top - containerRect.top + container.scrollTop + yOffset;
        if (element) {
            container.scrollTo({ top: y, behavior: 'smooth' });
        }
    }

    const handleSlideChange = () => {
        const swiperInstance = swiperRef.current.swiper;
        if (swiperInstance) {
            scrollToQuestTitle()
            if (swiperInstance.previousIndex < swiperInstance.activeIndex) {
                setActualSlide(actualSlide + 1)
            } else if (swiperInstance.previousIndex > swiperInstance.activeIndex) {
                setActualSlide(actualSlide - 1)
            }
        }
    };
    return (
        <div className='questions'
            ref={containerRef}
            tabIndex={0}
            onFocus={() => setSelecting(true)}
            onBlur={() => setSelecting(false)}>
            <Swiper
                modules={[Autoplay, Navigation]}
                spaceBetween={25}
                slidesPerView={1}
                ref={swiperRef}
                onSlideChange={handleSlideChange}
            >
                {questions.map((question, index) =>
                    <SwiperSlide>
                        <Question
                            key={index}
                            title={title}
                            subtitle={subtitle}
                            actualSlide={actualSlide}
                            nextSlide={nextSlide}
                            previousSlide={previousSlide}
                            currentTopicData={currentTopicData}
                            setCurrentTopicData={setCurrentTopicData}
                        >
                            {question}
                        </Question>
                    </SwiperSlide>)}
            </Swiper>
        </div>
    );
};