import React, { useState } from 'react';
import "../../css/DirectoryTree.css";
import { MdExpandLess } from "react-icons/md";
import { MdExpandMore } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import { createLabel } from './createLabel';
import { FaCircleArrowDown } from "react-icons/fa6";
import { FaCircleArrowUp } from "react-icons/fa6";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";


export default ({ data, setScrollToId }) => {
  const [expandedItems, setExpandedItems] = useState({});

  const toggleExpand = (name) => {
    setExpandedItems({
      ...expandedItems,
      [name]: !expandedItems[name]
    });
  };

  const handleItemClick = (id) => {
    setScrollToId(id)
  }

  const renderTree = (items, level = 0) => {
    return <ul className="directory-tree">
      {items.map(item => (
        <li key={item.name}>
          <div style={{ paddingLeft: level * 20 + 'px' }}>
            {item.type === 'directory' ? (
              <React.Fragment>
                <div className='directory flex align-items-center' onClick={() => toggleExpand(item.name)}>
                  <span className='directory-icon pe-1'>{expandedItems[item.name] ? <IoIosArrowDropupCircle fontSize={16} /> : <IoIosArrowDropdownCircle fontSize={16} />}</span>
                  <span>{item.name}</span>
                </div>
                {expandedItems[item.name] && (
                  renderTree(item.children, level + 1)
                )}
              </React.Fragment>
            ) : (
              <div
                onClick={() => handleItemClick(createLabel(item.name))}
                className='file'>
                <span className='item-icon'><GoDotFill /></span>{item.name}
              </div>
            )}
          </div>
        </li>
      ))}
    </ul>
  };

  return <div className='directory-tree-wrapper'>
    {renderTree(data)}
  </div>;
};