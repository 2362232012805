import { createLabel } from "../components/createLabel"

export default (relationalMap, topic) => {
    const graph = { nodes: [], edges: [], nodeMap: {} }
    const alreadyAddNodes = []
    const nodesSizes = {}

    Object.keys(relationalMap).forEach((key) => {
        let value = relationalMap[key]
        if (!alreadyAddNodes.includes(value)) {
            alreadyAddNodes.push(value)
            let newNode = {
                "id": alreadyAddNodes.length,
                "label": createLabel(value),
                "title": value,
                "end_of_the_road": false,
                "is_antithesis": false
            }

            graph.nodeMap[value] = newNode.id
            if (graph.nodeMap[key] !== undefined) {
                graph.edges.push({ from: graph.nodeMap[key], to: graph.nodeMap[value] })
            }

            graph.nodes.push(newNode)
        }

        if (!alreadyAddNodes.includes(key)) {
            alreadyAddNodes.push(key)
            let newNode = {
                "id": alreadyAddNodes.length,
                "label": createLabel(key),
                "title": key,
                "end_of_the_road": false,
                "is_antithesis": false
            }

            graph.nodeMap[key] = newNode.id
            graph.edges.push({ from: graph.nodeMap[key], to: graph.nodeMap[value] })
            graph.nodes.push(newNode)
        }

        if (Object.keys(nodesSizes).includes(value)) {
            nodesSizes[value] += 1
        } else {
            nodesSizes[value] = 1
        }
    })

    const minSize = 20
    graph.nodes = graph.nodes.map((node) => {
        node.size = nodesSizes[node.label] ? nodesSizes[node.label] * 5 + minSize : minSize;
        return node
    })

    graph.nodes = graph.nodes.map((node) => {
        if (node.label === createLabel(topic)) {
            node.is_main_topic = true
        }

        return node
    })

    return graph;
}