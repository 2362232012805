import constants from "../helpers/constants";

export default (nodeId, networkRef, setNodesToUpdateByHover, allPaths, setSelectedNode) => {
  if (networkRef.current === undefined) {
    return
  }

  const nodes = networkRef.current.body.data.nodes;
  if (nodeId !== null) {
    nodes.update({ id: nodeId, ...constants.graph.nodes.style.selected });
  }

  setNodesToUpdateByHover((prev) => {
    setSelectedNode((selectedNodePrev) => {
      const path = allPaths[selectedNodePrev].path
      if (path && (path.includes(prev))) {
        return selectedNodePrev
      }

      if (prev !== null) {
        nodes.update({ id: prev, ...constants.graph.nodes.style.normal });
      }
      return selectedNodePrev
    })

    return nodeId
  })

  return nodeId
}