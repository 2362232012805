import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import '../../css/SearchPage.css';

const SearchPage = () => {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (query.trim()) {
      navigate(`/${encodeURIComponent(query)}`);
    }
  };

  return (
    <div id="SearchPage" className='py-5'>
      <div className='d-flex w-100 justify-content-center pt-5'>
        <div className='title-wrapper d-inline-block pt-0 pt-sm-5'>
          <div className='d-flex justify-content-center align-items-center pt-5'>
            <img src="./logo512.png" alt="Logo" className="logo" />
            <h1 className="text-light display-1">Nodecharm</h1>
          </div>
        </div>
      </div>
      <div className="container text-center" style={{ maxWidth: '400px' }}>
        <form onSubmit={handleSearch} className="d-flex">
          <input
            type="text"
            className="form-control mr-2 mt-0 background-dark"
            placeholder="Enter a topic"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button type="submit" className="navbar-item ms-0 mt-0">
            Create
          </button>
        </form>
        <div className='warning'>
        <small>AI can make mistakes. Check important info.</small>
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
