const DB_NAME = 'nodecharm';
const DB_VERSION = 1;
const STORE_NAME = 'topics';

function initializeDB() {
    return new Promise((resolve, reject) => {
        if (!window.indexedDB) {
            console.error("Your browser doesn't support IndexedDB.");
            return;
        }

        const request = indexedDB.open(DB_NAME, DB_VERSION);

        request.onerror = (event) => {
            console.error('Database error:', event.target.error);
            reject(event.target.error);
        };

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: 'name' });
            }
        };
    });
}

export function addOrUpdateTopic(topic) {
    return new Promise(async (resolve, reject) => {
        const db = await initializeDB();
        const transaction = db.transaction([STORE_NAME], 'readwrite');
        const store = transaction.objectStore(STORE_NAME);
        const request = store.put(topic);

        request.onsuccess = () => {
            resolve();
        };

        request.onerror = (event) => {
            console.error('Add or update topic error:', event.target.errorCode);
            reject(event.target.errorCode);
        };
    });
}

export function deleteTopic(topic) {
    return new Promise(async (resolve, reject) => {
        const confirmed = window.confirm('Are you sure you want to delete this topic?');
        
        if (!confirmed) {
            reject('Deletion canceled');
            return;
        }
        
        const db = await initializeDB();
        const transaction = db.transaction([STORE_NAME], 'readwrite');
        const store = transaction.objectStore(STORE_NAME);

        const request = store.delete(topic);

        request.onsuccess = () => {
            resolve();
        };

        request.onerror = (event) => {
            console.error('Delete topic error:', event.target.errorCode);
            reject(event.target.errorCode);
        };
    });
}

export function getTopicByName(name) {
    return new Promise(async (resolve, reject) => {
        const db = await initializeDB();
        const transaction = db.transaction([STORE_NAME], 'readonly');
        const store = transaction.objectStore(STORE_NAME);
        const request = store.get(name);

        request.onsuccess = (event) => {
            if (event.target.result) {
                resolve(event.target.result);
            } else {
                console.warn('No topic found with the name', name);
                resolve({
                    name: name,
                    input: name,
                    status: 200
                });
            }
        };

        request.onerror = (event) => {
            console.error('Get Topic error:', event.target.errorCode);
            reject(event.target.errorCode);
        };
    });
}


export function getAllTopics() {
    return new Promise(async (resolve, reject) => {
        const db = await initializeDB();
        const transaction = db.transaction([STORE_NAME], 'readonly');
        const store = transaction.objectStore(STORE_NAME);
        const request = store.getAll();

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };

        request.onerror = (event) => {
            console.error('Get all topics error:', event.target.errorCode);
            reject(event.target.errorCode);
        };
    });
}