import React from 'react';
import '../../css/Loading.css';

const Loading = ({ isLoading }) => {
    return (
        <div className={`loading-container zoom-animation ${isLoading ? 'fade-in' : 'fade-out'}`}>
            <img src="./logo512.png" alt="Logo" className="loading-logo" />
            <div className="circle-container">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
            </div>
        </div>
    );
};

export default Loading;
