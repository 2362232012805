import { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useNavigate } from 'react-router-dom';
import "../../css/react-select-dark-theme.css";
import { FaSearch } from 'react-icons/fa';
import getSelectedOptionByTopic from '../helpers/getSelectedOptionByTopic';
import groupedOptions from '../../json/groupedOptions.json';
import { getAllTopics } from '../helpers/indexedDb';

const CustomPlaceholder = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <FaSearch style={{ marginRight: 8 }} />
        <span>Search a topic</span>
    </div>
);



const findObjectWithPersonalLabel = (objects) => {
    for (let i = 0; i < objects.length; i++) {
        if (objects[i].label === "Personal") {
            return i;
        }
    }
    return null;
}

function hasPersonalLabel(arr) {
    return arr.some(obj => obj.label.toLowerCase() === "personal");
}


export default ({ topic, setSelecting }) => {
    let navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState(getSelectedOptionByTopic(topic));
    const [selectOptions, setSelectOptions] = useState(groupedOptions);

    useEffect(() => {
        const getLocalGraphs = async () => {
            const localTopics = await getAllTopics()
            const newOption = {
                label: "Personal",
                options: []
            }

            localTopics.forEach((topic) => {
                newOption.options.push({
                    label: topic.name,
                    value: topic.name
                })
            })
            
            if (hasPersonalLabel(groupedOptions)){
                return 
            }

            groupedOptions.push(newOption)
            setSelectOptions(groupedOptions)
        }
        getLocalGraphs()
    }, [])

    const handleChange = (newSelection) => {
        if (newSelection === null) {
            return
        }

        navigate(`/${newSelection.value}`, { replace: true });
        window.location.reload();
    };

    const handleCreate = (inputValue) => {
        const newOption = { value: inputValue, label: inputValue };
        setSelectOptions((prev) => {
            const personalGroupIndex = findObjectWithPersonalLabel(prev)
            if (personalGroupIndex === null) {
                const newGroup = {
                    label: 'Personal',
                    options: [newOption],
                };

                return [...prev, newGroup]
            }

            prev[personalGroupIndex] = {
                label: 'Personal',
                options: [...prev[personalGroupIndex].options, newOption],
            };

            return prev
        });
        setSelectedOption(newOption);
        handleChange(newOption);
    };

    const handleFocus = () => {
        setSelecting(true)
    }

    const handleBlur = () => {
        setSelecting(false)
    };

    return (<CreatableSelect
        styles={{
            placeholder: (provided) => ({
              ...provided,
              pointerEvents: "none",
              userSelect: "none",
              MozUserSelect: "none",
              WebkitUserSelect: "none",
              msUserSelect: "none"
            }),
            input: (provided) => ({
              ...provided,
              input: {
                gridArea: '1 / 2 / 4 / 4 !important'
              }
            }),
          }}
        classNamePrefix='react-select-dark'
        isClearable
        onChange={handleChange}
        onCreateOption={handleCreate}
        onFocus={handleFocus}
        onBlur={handleBlur}
        options={selectOptions}
        placeholder={CustomPlaceholder}
        noOptionsMessage={() => "Search a topic"}
    />
    );
}