import constants from "../helpers/constants";

const getEdgeIdByPathIndex = (path, index, edgesMap) => {
  const from = path[index]

  const to = path[index + 1]
  if (to === undefined) {
    return;
  }

  const edgeId = edgesMap[`${from}:${to}`] ? `${from}:${to}` : `${to}:${from}`

  return edgeId
}

export default (path, edgesMap, setEdgesToUpdate, networkRef) => {
  if (path === undefined) {
    return
  }

  if (networkRef.current === undefined) {
    return
  }

  const edges = networkRef.current.body.data.edges;
  const edgesIdsFromPath = path.map((_, index) => { return getEdgeIdByPathIndex(path, index, edgesMap) })

  setEdgesToUpdate((prevEdgesToUpdate) => {
    prevEdgesToUpdate.forEach((_, index) => {
      const edgeId = getEdgeIdByPathIndex(prevEdgesToUpdate, index, edgesMap)
      if (!edgesIdsFromPath.includes(edgeId)) {
        edges.update({ id: edgeId, ...constants.graph.edges.style.normal });
      }
    });
    return path
  })

  path.forEach((_, index) => {
    const edgeId = getEdgeIdByPathIndex(path, index, edgesMap)
    const edge = edgesMap[edgeId] ?? edgesMap[edgeId]
    if (edge === undefined) {
      return;
    }

    edges.update({ id: edgeId, ...constants.graph.edges.style.selected });
  });


}