import { Button } from "@nextui-org/button";

export default ({ searchTerm }) => {
    const externalSearchMap = {
        'Google': `https://www.google.com/search?q=${searchTerm}`,
        'Youtube': `https://www.youtube.com/results?search_query=${searchTerm}`,
        'Reddit': `https://www.reddit.com/search/?q=${searchTerm}`,
        'Wikipedia': `https://en.wikipedia.org/wiki/Special:Search?search=${searchTerm}`,
        'Google Scholar': `https://scholar.google.com/scholar?q=${searchTerm}`
    }
    return <div className="mb-3">
        <h1 className='h6'>Search on</h1>
        <a className="navbar-item ms-0" target="_blank" href={externalSearchMap['Youtube']}>Youtube</a>
        <a className="navbar-item ms-0" target="_blank" href={externalSearchMap['Google']}>Google</a>
        <a className="navbar-item ms-0" target="_blank" href={externalSearchMap['Reddit']}>Reddit</a>
        <a className="navbar-item ms-0" target="_blank" href={externalSearchMap['Wikipedia']}>Wikipedia</a>
        <a className="navbar-item ms-0" target="_blank" href={externalSearchMap['Google Scholar']}>Google Scholar</a>
    </div>
}