import groupedOptions from '../../json/groupedOptions.json';

export default (topic) => {
    let selectedOption = null
    groupedOptions.forEach((group) => {
        group.options.forEach((option) => {
            if (option.value === topic) {
                selectedOption = option
            }
        })
    })

    return selectedOption
}